import React from "react"
import { cruise, showEarlyBirdInfo } from "./../../constants"
import { ButtonRow, Button } from "./../UI/Button/Button"

export const CruiseDate = ({className}) => (
  <span className={className}>
    {cruise.date}
  </span>
)

export const CruiseRoute = ({className}) => (
  <span className={className}>
    {cruise.route}
  </span>
)

export const CruiseShip = ({className}) => (
  <a 
    href={cruise.shipLink}
    className={className}
    target="_blank"
    rel="noopener noreferrer"
  >
    {cruise.ship}
  </a>
)

export const CruisingCompany = ({className}) => (
  <a 
    href={cruise.shippingCompanyLink}
    className={className}
    target="_blank"
    rel="noopener noreferrer"
  >
    MSC Kreuzfahrten
  </a>
)

export const BookActions = ({className}) => (
  <ButtonRow hasVerticalSpace={true}>
    <Button link="/cruise">
      Reiseanmeldung
    </Button>
    <Button link="/conference">
      Kongressanmeldung
    </Button>
    <Button link="/manifest">
      Bordmanifest
    </Button>
  </ButtonRow>
)

export const EarlyBirdDiscount = ({className}) => {
  if (showEarlyBirdInfo) {
    return (
      <p className={className} style={{}}>
        100 € Frühbucherrabatt auf die Fortbildung bis 30.11.25
      </p>
    )
  }
  return null
}